import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class HomeBanner {
  @JsonProperty("home_banner_id") id!: number

  @JsonProperty() link!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
