




























import Component, { mixins } from "vue-class-component"
// @ts-ignore
import NextBtn from "@/assets/buttons/next-white.png"
// @ts-ignore
import PrevBtn from "@/assets/buttons/prev-white.png"
import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeCarousel from "@/types/Home/HomeCarousel"
import HomeApi from "@/api/home/HomeApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import { Watch } from "vue-property-decorator"

@Component({
  name: "SkewedCarousel",
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class SkewedCarousel extends mixins(InputMixin, BreakpointMixin) {
  private nextBtn = NextBtn

  private prevBtn = PrevBtn

  private carousels: HomeCarousel[] = []

  @Watch("$route", { deep: true, immediate: true })
  private onRouteChange() {
    this.fetchData()
  }

  private mounted() {
    this.fetchData()
  }

  private fetchData() {
    HomeApi.listCarousel().then(({ data }) => {
      this.carousels = _.map(data, (d) => deserialize<HomeCarousel>(d, HomeCarousel))
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private viewImage(image: HomeCarousel) {
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(`skew-carousel-${image.id}`)?.click()
  }

  get skewOptions(): SwiperOptions {
    return {
      autoplay: true,
      slidesPerView: 1,
      preloadImages: true,
      // centeredSlides: this.mobileScreen,
      spaceBetween: 10,
      updateOnWindowResize: true,
      // slidesPerGroup: 1,
      loop: !this.mobileScreen,
      // loopFillGroupWithBlank: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: "#skew-next",
        prevEl: "#skew-prev"
      },
      breakpoints: {
        1920: {
          slidesPerView: 4,
          spaceBetween: 420
        },
        1900: {
          slidesPerView: 4,
          spaceBetween: 200
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 180
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 350
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 250
        },
        650: {
          slidesPerView: 2
          // spaceBetween: 270,
        },
        100: {
          slidesPerView: 1
        }
      }
    }
  }

  get contentSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xl) {
      return {
        width: "540px",
        height: "342px",
        "margin-bottom": "20px"
      }
    }
    return this.mobileScreen
      ? {
          width: "306px",
          height: "194px",
          "margin-bottom": "20px"
        }
      : {
          width: "360px",
          height: "230px"
        }
  }
}
