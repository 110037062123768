
























































import Component, { mixins } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
// @ts-ignore
import AOS from "aos"
import _ from "lodash"

import HomeActivity from "@/types/Home/HomeActivity"
import BreakpointMixin from "@/types/BreakpointMixin"
import NewsApi from "@/api/News/NewsApi"

import CardActivities from "@/components/Home/CardActivities.vue"

@Component({
  name: "Activities",
  components: {
    Swiper,
    SwiperSlide,
    CardActivities
  }
})
export default class Activities extends mixins(BreakpointMixin) {
  private data: HomeActivity[] = []

  private activity = new HomeActivity()

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private seeMore() {
    const lang = this.$i18n.locale
    this.$router.push({ name: "NewsListPage", query: { top: "true", lang } })
  }

  private fetchData() {
    NewsApi.getAllNews()
      .then(({ data }) => {
        this.data = _.map(data, (d) => deserialize<HomeActivity>(d, HomeActivity))
        ;[this.activity] = this.data
      })
      .then(() => {
        AOS.refresh()
      })
  }

  private mounted() {
    this.fetchData()
  }

  private viewNews(item: HomeActivity) {
    const lang = this.$i18n.locale
    this.$router.push({
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang }
    })
  }

  private generateRoute(item: HomeActivity) {
    return {
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang: this.$i18n.locale }
    }
  }

  private get heroWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 623
    }
    return 400
  }

  private get heroHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 414
    if (bp.lgOnly) return 275
    return 200
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 243
    }
    return 162
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 122
    }
    return 81
  }

  get storageLang() {
    return this.$route.query.lang || localStorage.getItem("lang") || "th"
  }

  // eslint-disable-next-line class-methods-use-this
  private swiperOptions: SwiperOptions = {
    // slidesPerGroup: 1,
    loop: true,
    // loopFillGroupWithBlank: false,
    autoplay: true,
    preloadImages: true,
    updateOnImagesReady: true,
    updateOnWindowResize: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1
    },
    slidesPerView: 1,
    centeredSlides: true,
    watchOverflow: true,
    breakpoints: {
      991: {
        slidesPerView: 3
      },
      100: {
        slidesPerView: 1,
        // slidesPerGroup: 3,
        centeredSlides: true,
        spaceBetween: 1,
        watchOverflow: true,
        slidesOffsetBefore: 1,
        slidesOffsetAfter: 1
      }
    }
  }
}
