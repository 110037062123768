





















































































import Component, { mixins } from "vue-class-component"
import HomeApi from "@/api/home/HomeApi"
import HomeMarketPlace from "@/types/Home/HomeMarketPlace"
import { deserialize } from "typescript-json-serializer"
import BreakpointMixin from "@/types/BreakpointMixin"
import { Watch } from "vue-property-decorator"

@Component({
  name: "Announcement"
})
export default class Announcement extends mixins(BreakpointMixin) {
  private announcement = true

  private marketplace = new HomeMarketPlace()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    HomeApi.getMarketPlace().then(({ data }) => {
      this.marketplace = deserialize<HomeMarketPlace>(data, HomeMarketPlace)
    })
  }

  private mounted() {
    this.fetchData()
  }

  private closeAnnouncement() {
    this.announcement = false
  }

  private get width() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 350
    return 235
  }

  private get closeSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return "50px"
    return "30px"
  }

  private get textBoxWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return "250px"
    return "180px"
  }

  private get textBoxHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return "175px"
    return "120px"
  }

  private get textSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return "14px"
    return "10px"
  }
}
