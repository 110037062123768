import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class HomeMarketPlace {
  @JsonProperty("home_market_place_id") id!: number

  @JsonProperty() content!: string

  @JsonProperty() status!: string
}
