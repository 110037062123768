import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import { dateToLocalString } from "@/types/News/helper"

@Serializable()
export default class News {
  @JsonProperty("news_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty({ name: "created_date", beforeDeserialize: dateToLocalString })
  createdDate!: string

  @JsonProperty() sort!: number

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile = new DiamondFile()
}
