























import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import _ from "lodash"

/** Types */
import BreakpointMixin from "@/types/BreakpointMixin"
import News from "@/types/News/News"

@Component({
  name: "CardActivities"
})
export default class CardProductServices extends mixins(BreakpointMixin) {
  @Prop()
  type!: string

  @Prop({
    default: {
      title: "กระเบื้อง CT เพชร"
    }
  })
  activity!: News

  get imageHeight() {
    const width = window.screen.width
    if (width > 1440) {
      return this.type === "image" ? `606px` : `298px`
    } else if (width > 1280) {
      return this.type === "image" ? `404px` : `199px`
    } else {
      return this.type === "image" ? `210px` : `210px`
    }
  }

  get storageLang() {
    return localStorage.getItem("lang") || "th"
  }
}
