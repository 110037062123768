






























import Component, { mixins } from "vue-class-component"
import { Watch, Prop } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { SwiperOptions } from "swiper"
import _ from "lodash"

import HomeApi from "@/api/home/HomeApi"
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeArticles from "@/types/Home/HomeArticles"

import CardArticle from "@/components/Home/CardArticle.vue"
@Component({
  name: "HomeArticles",
  components: {
    Swiper,
    SwiperSlide,
    CardArticle
  }
})
export default class Articles extends mixins(BreakpointMixin) {
  @Prop({ default: "550px" }) height!: string

  /** Variables */
  private articles: HomeArticles[] = []
  private isReady: boolean = false

  /** Swiper Config */
  // // eslint-disable-next-line class-methods-use-this
  get swiperOptions(): SwiperOptions {
    return {
      slidesPerGroup: 1,
      // loop: true,
      loop: this.articles.length > 4,
      loopFillGroupWithBlank: true,
      autoplay: this.articles.length > 4,
      preloadImages: true,
      updateOnImagesReady: true,
      updateOnWindowResize: true,
      spaceBetween: 24,
      centeredSlides: this.articles.length < 4 && this.articles.length !== 2,
      centerInsufficientSlides: this.articles.length === 2,
      initialSlide: this.articles.length < 3 ? 0 : this.articles.length < 5 ? 1 : 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 1
      },
      // centeredSlides: true,
      breakpoints: {
        991: {
          slidesPerView: 4
          // autoplay: true
        },
        100: {
          slidesPerView: 1
          // autoplay: true,
          // slidesPerGroup: 3,
          // centeredSlides: true
        }
      }
    }
  }

  get swiperOptionsXs(): SwiperOptions {
    return {
      slidesPerGroup: 1,
      loop: true,
      loopFillGroupWithBlank: false,
      autoplay: true,
      preloadImages: true,
      updateOnImagesReady: true,
      updateOnWindowResize: true,
      spaceBetween: 24,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 1
      }
    }
  }

  /** Cycles */
  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.fetchData()
  }

  private mounted() {
    this.fetchData()
  }

  /** Functions */
  private fetchData() {
    this.isReady = false
    HomeApi.getArticles().then(({ data }) => {
      this.articles = _.map(data, (d) => deserialize<HomeArticles>(d, HomeArticles))
    })
    setTimeout(() => (this.isReady = true), 500)
  }

  private seeMore() {
    const lang = this.$i18n.locale
    this.$router.push({ name: "NewsListPage", query: { top: "true", lang } })
  }

  private viewNews(item: HomeArticles) {
    const lang = this.$i18n.locale
    this.$router.push({
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang }
    })
  }

  private generateRoute(item: HomeArticles) {
    return {
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang: this.$i18n.locale }
    }
  }

  /** Getter */
  private get heroWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 623
    }
    return 400
  }

  private get heroHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 414
    if (bp.lgOnly) return 275
    return 200
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 243
    }
    return 162
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 122
    }
    return 81
  }

  get storageLang() {
    return this.$route.query.lang || localStorage.getItem("lang") || "th"
  }
}
