

























import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import _ from "lodash"

/** Types */
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeProductServices from "@/types/Home/HomeProductServices"

@Component({
  name: "CardProductServices"
})
export default class CardProductServices extends mixins(BreakpointMixin) {
  @Prop({
    default: {
      title: "กระเบื้อง CT เพชร",
      content: "โดดเด่นด้วยรูปลอน Gran Onda ให้ความโค้งเว้าได้รูปแกร่งทนด้วยมาตรฐาน การผลิตอันทันสมัย",
      link: "https://www.youtube.com/",
      lang: "TH"
    }
  })
  product!: HomeProductServices

  get imageHeight() {
    const width = window.screen.width
    return width > 1440 ? "550px" : width > 1280 ? "440px" : width < 991 ? "307px" : "370px"
  }
}
